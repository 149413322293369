import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { abilitiesSelector } from "../../../../../../selectors/user";
import {
  addPackedBoxes,
  updatePackedBoxes
} from "../../../../../../actions/PackedBoxes/packedBoxes";
import _ from "lodash";
import { getParentPageHref } from "../../../../../../utils/routeHelpers";
import { putMessage } from "../../../../../../actions/InfoMessages";
import { initialValues as ScanPackedBoxesFormInitialValues } from "../../ScanPackedBoxes/ScanPackedBoxesForm";
import { getErrorFields } from "../../../../../../utils/validationRules";
import { scrollIntoError } from "../../../../../../utils/scrollIntoError";
import EditPackedBoxesFormContent from "../EditPackedBoxesFormContent";

const initialValues = {
  ...ScanPackedBoxesFormInitialValues
};

class EditPackedBoxesForm extends Component {
  constructor(props) {
    super(props);
    let modifiedInitialValues = props.initialValues
      ? {
          ...props.initialValues
        }
      : null;

    this.state = {
      ...(modifiedInitialValues || initialValues),
      errorsInSegments: {},
      activeItems: {}
    };

    this.formRef = React.createRef();
  }

  handleErrors = () => {
    const { formRef } = this.props;
    const formsyForm = (formRef || this.formRef).current.formsyForm;
    const errorFields = getErrorFields(formsyForm);
    this.setState({
      errorsInSegments: {
        information: {
          error: errorFields.name
        }
      }
    });
    setTimeout(scrollIntoError, 300);
  };

  onValidSubmit = async () => {
    const { actions, onSubmitForm, route, initialValues } = this.props;
    const {
      scannedAt,
      barcodeId,
      area,
      boxUnit,
      packLocation,
      packingShed,
      intendedDestination,
      notes,
      packedBoxes,
      pickingPrice,
      paddingPrice,
      cartingPrice
    } = this.state;
    if (this.isSubmitted) {
      return null;
    }
    this.isSubmitted = true;

    const request = {
      scannedAt,
      barcodeId,
      areaId: area.id,
      boxUnit: { id: boxUnit.id },
      packLocation: packLocation,
      packingShed: { id: packingShed.id },
      intendedDestination,
      notes,
      pickingPrice,
      paddingPrice,
      cartingPrice
    };

    this.setState({
      errorsInSegments: {}
    });

    let res;

    if (!initialValues || !initialValues.id) {
      res = await actions.addPackedBoxes(request);
    } else {
      res = await actions.updatePackedBoxes(initialValues.id, request);
    }

    this.isSubmitted = false;

    if (res.error) {
      if (
        res.error.response &&
        res.error.response.data &&
        res.error.response.data.code === 4000 &&
        res.error.response.data.body
      ) {
        this.setState({
          packedBoxes: packedBoxes.map(packedBox => ({
            ...packedBox,
            error:
              res.error.response.data.body.filter(
                alreadyScannedBarcode =>
                  alreadyScannedBarcode.barcode === packedBox.barcode
              ).length !== 0
          }))
        });
      }
    } else {
      if (_.isFunction(onSubmitForm)) {
        onSubmitForm(res);
      } else {
        this.props.navigate(getParentPageHref(route));
      }
    }
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  changeValue = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { formRef } = this.props;
    const {
      id,
      barcodeId,
      scannedAt,
      area,
      boxUnit,
      packLocation,
      packingShed,
      intendedDestination,
      notes,
      pickingPrice,
      paddingPrice,
      cartingPrice
    } = this.state;

    return (
      <EditPackedBoxesFormContent
        formRef={formRef}
        changeValue={this.changeValue}
        onValidSubmit={this.onValidSubmit}
        values={{
          id,
          barcodeId,
          scannedAt,
          area,
          boxUnit,
          packLocation,
          packingShed,
          intendedDestination,
          notes,
          pickingPrice,
          paddingPrice,
          cartingPrice
        }}
      />
    );
  }
}

EditPackedBoxesForm.propTypes = {
  formRef: PropTypes.func,
  onSubmitForm: PropTypes.func,
  initialValues: PropTypes.object,
  actions: PropTypes.object,
  route: PropTypes.object
};

const mapStateToProps = state => {
  return {
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addPackedBoxes,
      updatePackedBoxes,
      putMessage
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPackedBoxesForm);
