import React from "react";
import filterTypes from "components/Table/filterTypes";
import BasicCell from "components/Table/components/BasicCell";
import { areaFilterOptions } from "selectors/areas";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import moment from "moment";
import { employeeOptionsSelector } from "selectors/employee";
import { contractorFilterOptions } from "selectors/generalTasks";
import { varietiesOptionSelector } from "selectors/areas";
import { boxUnitOptionsSelector } from "selectors/boxUnits";
import { Button, Icon, Popup } from "semantic-ui-react";
import style from "../routes/Harvest/PackedBoxes/PackedBoxes.module.css";
import { Link } from "react-router-dom";
export const getPackedShedTableColumns = (isFetching, navigate, onDelete) => {
  const columnsConfig = {
    id: {
      withSort: true,
      type: "string",
      id: "id",
      title: "id",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ barcodeId }) => barcodeId,
      withSort: true,
      filterId: "seasons",
      filter: {
        title: "Season",
        type: filterTypes.SingleSelect,
        selector: seasonsOptionsSelector
      },
      cellWidth: 2
    },
    scannedAt: {
      withSort: true,
      type: "scannedAt",
      id: "scannedAt",
      title: "Date",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ scannedAt }) => moment(scannedAt).format("DD/MM/YYYY"),
      withSort: true,
      filterId: "date",
      filter: {
        title: "Date",
        type: filterTypes.DateRangeSelect,
        selector: <DateRangeSelect loading={isFetching} />
      },
      cellWidth: 2
    },
    employee: {
      withSort: true,
      type: "string",
      id: "employee",
      title: "Employee",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ employee }) =>
        `${employee?.firstName} ${employee?.lastName}` || "-",
      withSort: true,
      cellWidth: 2,
      filterId: "employee",
      filter: {
        title: "Employee",
        type: filterTypes.MultiSelect,
        selector: employeeOptionsSelector
      },
      cellWidth: 2
    },

    contractor: {
      withSort: true,
      type: "string",
      id: "contractor",
      title: "Contractor",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ employee }) =>
        employee?.contractor ? employee?.contractor.name : "-",
      withSort: true,
      filterId: "contractor",
      filter: {
        title: "Contractor",
        type: filterTypes.MultiSelect,
        selector: contractorFilterOptions
      },
      cellWidth: 2
    },
    area: {
      withSort: true,
      type: "string",
      id: "area",
      title: "Area",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ area }) => area?.name || "-",
      withSort: true,
      filterId: "area",
      filter: {
        title: "Area",
        type: filterTypes.MultiSelect,
        selector: areaFilterOptions
      },
      cellWidth: 2
    },
    variety: {
      withSort: true,
      type: "string",
      id: "variety",
      title: "Variety",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ area }) =>
        area?.varieties.map(item => item.variety.name).join(", ") || "-",
      withSort: true,
      cellWidth: 2,
      filterId: "variety",
      filter: {
        title: "Variety",
        type: filterTypes.MultiSelect,
        selector: state => varietiesOptionSelector({})(state)
      },
      cellWidth: 2
    },
    boxUnit: {
      withSort: false,
      type: "string",
      id: "boxUnit",
      title: "BoxUnit",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ boxUnit }) => boxUnit.displayName || "-",
      withSort: true,
      cellWidth: 2,
      filterId: "boxUnit",
      filter: {
        title: "Box unit",
        type: filterTypes.MultiSelect,
        selector: boxUnitOptionsSelector
      },
      cellWidth: 2
    },
    pickingPrice: {
      withSort: false,
      type: "product",
      id: "pickingPrice",
      title: "Picking price",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ paddingPrice }) => paddingPrice || "-",
      withSort: true,
      cellWidth: 2
    },
    paddingPrice: {
      withSort: true,
      type: "string",
      id: "paddingPrice",
      title: "Padding price",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ pickingPrice }) => pickingPrice || "-",
      withSort: true,
      cellWidth: 2
    },
    cratingPrice: {
      withSort: true,
      type: "string",
      id: "cratingPrice",
      title: "Crating price",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ cartingPrice }) => cartingPrice || "-",
      withSort: true,
      cellWidth: 2
    },
    btnWrapper: {
      withSort: false,
      type: "string",
      id: "btnWrapper",
      title: "",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ id }) => {
        return (
          <div className={style.wrapper}>
            <div className={style.iconWrapper}>
              <Popup
                inverted
                position="bottom center"
                content={"Edit"}
                size="tiny"
                trigger={
                  <div className={style.editButtonWrapper}>
                    <Button
                      as={Link}
                      to={`/harvest/packed_boxes/edit/${id}`}
                      onClick={event => {
                        return event.stopPropagation();
                      }}
                      className={style.editButton}
                      floated="right"
                      icon={<Icon name="edit" />}
                      size="small"
                    />
                  </div>
                }
              />
              <Popup
                inverted
                position="bottom center"
                content={"Delete"}
                size="tiny"
                trigger={
                  <Button
                    onClick={event => {
                      return onDelete(id), event.stopPropagation();
                    }}
                    className={style.deleteButton}
                    floated="right"
                    icon={<Icon name="trash alternate" />}
                    size="small"
                  />
                }
              />
            </div>
          </div>
        );
      },
      withSort: true,
      cellWidth: 2
    }
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {})
  }));
};
