import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { abilitiesSelector } from "../../../../../../selectors/user";
import _ from "lodash";
import { getParentPageHref } from "../../../../../../utils/routeHelpers";
import { initialValues as ScanPackedBoxesFormInitialValues } from "../../ScanPackedBoxes/ScanPackedBoxesForm";
import { getErrorFields } from "../../../../../../utils/validationRules";
import { scrollIntoError } from "../../../../../../utils/scrollIntoError";
import EditPackedBoxesFormContent from "../../EditPackedBoxes/EditPackedBoxesFormContent";
import { batchUpdatePackedBoxes } from "../../../../../../actions/PackedBoxes/packedBoxes";

const initialValues = {
  ...ScanPackedBoxesFormInitialValues
};

class BatchEditPackedBoxesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...(props.initialValues || initialValues),
      errorsInSegments: {},
      activeItems: {}
    };

    this.formRef = React.createRef();
  }

  handleErrors = () => {
    const { formRef } = this.props;
    const formsyForm = (formRef || this.formRef).current.formsyForm;
    const errorFields = getErrorFields(formsyForm);
    this.setState({
      errorsInSegments: {
        information: {
          error: errorFields.name
        }
      }
    });
    setTimeout(scrollIntoError, 300);
  };

  onValidSubmit = async () => {
    const { actions, onSubmitForm, route, ids, navigate } = this.props;
    const {
      scannedAt,
      area,
      boxUnit,
      packLocation,
      packingShed,
      intendedDestination,
      notes,
      pickingPrice,
      paddingPrice,
      cartingPrice,
      activeItems
    } = this.state;
    if (this.isSubmitted) {
      return null;
    }
    this.isSubmitted = true;

    const requestProps = {
      scannedAt,
      areaId: area.id,
      boxUnit: { id: boxUnit.id },
      packLocation: packLocation,
      packingShed: { id: packingShed.id },
      intendedDestination,
      notes,
      pickingPrice,
      paddingPrice,
      cartingPrice
    };
    const request = { packedBoxes: ids };

    Object.keys(activeItems).forEach(item => {
      if (activeItems[item]) {
        request[item] = requestProps[item];
      }
    });

    this.setState({
      errorsInSegments: {}
    });
    const res = await actions.batchUpdatePackedBoxes(
      request,
      this.props.packedBoxes
    );
    this.isSubmitted = false;

    if (!res.error) {
      if (_.isFunction(onSubmitForm)) {
        onSubmitForm(res);
      } else {
        navigate(getParentPageHref(route));
      }
    }
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  changeValue = (name, value) => {
    this.setState({ [name]: value });
  };

  isActive = name => {
    const { activeItems } = this.state;
    return activeItems[name];
  };

  setActive = (name, value) => {
    const { activeItems } = this.state;
    this.setState({
      activeItems: {
        ...activeItems,
        [name]: value
      }
    });
  };

  render() {
    const {
      formRef,
      ids,
      showCheckbox,
      doSubmitForm,
      packedBoxes
    } = this.props;
    const {
      scannedAt,
      area,
      boxUnit,
      packLocation,
      packingShed,
      intendedDestination,
      notes,
      pickingPrice,
      paddingPrice,
      cartingPrice
    } = this.state;

    return (
      <EditPackedBoxesFormContent
        formRef={formRef}
        changeValue={this.changeValue}
        onValidSubmit={this.onValidSubmit}
        doSubmitForm={doSubmitForm}
        showCheckbox={showCheckbox}
        isActive={this.isActive}
        setActive={this.setActive}
        values={{
          ids,
          scannedAt,
          area,
          boxUnit,
          packLocation,
          packingShed,
          intendedDestination,
          notes,
          pickingPrice,
          paddingPrice,
          cartingPrice
        }}
      />
    );
  }
}

BatchEditPackedBoxesForm.propTypes = {
  ids: PropTypes.any,
  showCheckbox: PropTypes.bool,
  onSubmitForm: PropTypes.func,
  doSubmitForm: PropTypes.func,
  formRef: PropTypes.func,
  initialValues: PropTypes.object,
  route: PropTypes.object,
  actions: PropTypes.object
};

const mapStateToProps = state => {
  const {
    packedBoxes: {
      isFetching,
      data: { content }
    }
  } = state;
  return {
    packedBoxes: content,
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ batchUpdatePackedBoxes }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchEditPackedBoxesForm);
