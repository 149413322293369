import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Icon, Input, Segment } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { validationErrors } from "../../../../../../utils/validationRules";
import ErrorLabel from "../../../../../../components/ErrorLabel";
import { abilitiesSelector } from "../../../../../../selectors/user";
import styles from "./ScanPackedBoxesForm.module.css";
import BarcodesTable from "./BarcodesTable";
import {
  addPackedBoxes,
  updatePackedBoxes
} from "../../../../../../actions/PackedBoxes/packedBoxes";
import { validateBarcode } from "../../../../../../utils/employeesBarcodes";
import _ from "lodash";
import {
  infoMessageType,
  messagesIds,
  putMessage
} from "../../../../../../actions/InfoMessages";
import PackedBoxesDetails from "../../PackedBoxesDetails";
import QrScanner from "../../../../../../components/QrScanner/QrScanner";

const scanModes = {
  INPUT: "INPUT",
  CAMERA: "CAMERA"
};

export const initialValues = {
  scannedAt: moment().format("YYYY-MM-DD"),
  area: { id: null },
  boxUnit: { id: null },
  packLocation: null,
  packingShed: { id: null },
  intendedDestination: null,
  notes: "",
  packedBoxes: [],
  scanMode: scanModes.INPUT
};

class ScanPackedBoxesForm extends Component {
  constructor(props) {
    super(props);
    let modifiedInitialValues = props.initialValues
      ? {
          ...props.initialValues,
          packedBoxes: props.initialValues.packedBoxes.map(item => ({
            barcode: item.barcode
          }))
        }
      : null;

    this.state = {
      ...(modifiedInitialValues || initialValues)
    };

    this.formRef = React.createRef();
    this.gunBarcode = React.createRef();
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("input", this.handleInput);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("input", this.handleInput);
  }

  handleInput = e => {
    const value = e.data;
    if (validateBarcode(value)) {
      this.addBarcode(value);
      e.preventDefault();
    }
  };

  handleKeyDown(e) {
    if (e.key === "Unidentified" && e.keyCode === 0) {
      this.gunBarcode.current.focus();
    }
  }

  onValidSubmit = async () => {
    const { actions, onSubmitForm, initialValues } = this.props;
    const {
      scannedAt,
      area,
      boxUnit,
      packLocation,
      packingShed,
      intendedDestination,
      notes,
      packedBoxes
    } = this.state;
    if (this.isSubmitted) {
      return null;
    }
    this.isSubmitted = true;

    const request = {
      scannedAt,
      areaId: area.id,
      boxUnit: { id: boxUnit.id },
      packLocation: packLocation,
      packingShed: { id: packingShed.id },
      intendedDestination,
      notes,
      packedBoxes: packedBoxes.map(packedBox => packedBox.barcode)
    };
    let res;

    if (!initialValues || !initialValues.id) {
      res = await actions.addPackedBoxes(request);
    } else {
      res = await actions.updatePackedBoxes(initialValues.id, request);
    }

    this.isSubmitted = false;

    if (res.error) {
      if (
        res.error.response &&
        res.error.response.data &&
        res.error.response.data.code === 4000 &&
        res.error.response.data.body
      ) {
        this.setState({
          packedBoxes: packedBoxes.map(packedBox => ({
            ...packedBox,
            error:
              res.error.response.data.body.filter(
                alreadyScannedBarcode =>
                  alreadyScannedBarcode === packedBox.barcode
              ).length !== 0
          }))
        });
      }
    } else {
      if (_.isFunction(onSubmitForm)) {
        onSubmitForm(res);
      } else {
        this.props.navigate("/harvest/packed_boxes");
      }
    }
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  addBarcode = barcode => {
    const { packedBoxes } = this.state;
    const {
      actions: { putMessage }
    } = this.props;

    if (packedBoxes.findIndex(item => item.barcode === barcode) === -1) {
      this.setState({ packedBoxes: [...packedBoxes, { barcode }] });
    } else {
      putMessage({
        type: infoMessageType.WARN,
        messageId: messagesIds.GENERIC,
        duration: {
          amount: 1,
          unit: "second"
        },
        extraData: {
          message: `Barcode ${barcode} already scanned`
        }
      });
    }
  };

  removeBarcode = barcode => {
    const { packedBoxes } = this.state;
    this.setState({
      packedBoxes: packedBoxes.filter(item => item.barcode !== barcode.barcode)
    });
  };

  changeValue = (name, value) => {
    this.setState({ [name]: value });
  };

  switchScanMode = () => {
    const { setSaveButtonActivity } = this.props;
    const { scanMode } = this.state;
    const newScanMode =
      scanMode === scanModes.INPUT ? scanModes.CAMERA : scanModes.INPUT;
    this.setState({ scanMode: newScanMode });
    setSaveButtonActivity(newScanMode === scanModes.INPUT);
  };

  render() {
    const { Can, showMobileHeader, formRef } = this.props;
    const {
      scannedAt,
      area,
      boxUnit,
      packLocation,
      packingShed,
      intendedDestination,
      notes,
      packedBoxes,
      scanMode
    } = this.state;
    const columnMiddle = {
      mobile: 16,
      tablet: 16,
      computer: 10,
      largeScreen: 10,
      widescreen: 10
    };

    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.handleErrors}
        loading={false}
        autoComplete="off"
        ref={formRef || this.formRef}
      >
        <div className={`${styles.sprayGrid}`}>
          <div className={styles.sprayFormContainer} id="sprayGrid">
            <div>
              <Segment>
                <Grid
                  className={scanMode === scanModes.CAMERA ? styles.hide : ""}
                >
                  <PackedBoxesDetails
                    scannedAt={scannedAt}
                    area={area}
                    boxUnit={boxUnit}
                    packLocation={packLocation}
                    packingShed={packingShed}
                    intendedDestination={intendedDestination}
                    notes={notes}
                    changeValue={this.changeValue}
                  />
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Input
                        ref={this.gunBarcode}
                        onKeyDown={e => {
                          if (e.key === "Unidentified") {
                            e.target.blur();
                          }
                        }}
                        name="gunBarcode"
                        style={{ opacity: 0 }}
                        inputMode="none"
                      />
                      <BarcodesTable
                        barcodes={packedBoxes}
                        addBarcode={this.addBarcode}
                        removeBarcode={this.removeBarcode}
                      />
                      <Form.Input
                        name="barcodesCount"
                        value={packedBoxes.length}
                        type="hidden"
                        validations={"AtLeaseOneBarcode"}
                        validationErrors={validationErrors()}
                        errorLabel={ErrorLabel}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="hide-lg">
                    <Grid.Column>
                      <Button
                        onClick={this.switchScanMode}
                        type="button"
                        className="button-text"
                      >
                        <Icon className={`camera ${styles.scanButton}`} />
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {scanMode === scanModes.CAMERA && (
                  <QrScanner
                    items={packedBoxes}
                    validateBarcode={validateBarcode}
                    addAction={this.addBarcode}
                    onDone={this.switchScanMode}
                  />
                )}
              </Segment>
            </div>
          </div>
          {!showMobileHeader && (
            <div className={`${styles.sprayFormFooter} show-sm`}>
              <div className="text-right">
                <Can I="add" a="packed_boxes">
                  <Button
                    primary
                    size="large"
                    type="button"
                    disabled={scanMode === scanModes.CAMERA}
                    onClick={this.onSubmit}
                  >
                    Save
                  </Button>
                </Can>
              </div>
            </div>
          )}
        </div>
      </Form>
    );
  }
}

ScanPackedBoxesForm.propTypes = {
  onSubmitForm: PropTypes.func,
  formRef: PropTypes.func,
  Can: PropTypes.func,
  setSaveButtonActivity: PropTypes.func,
  showMobileHeader: PropTypes.bool,
  initialValues: PropTypes.object,
  actions: PropTypes.object
};

const mapStateToProps = state => {
  return {
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addPackedBoxes,
      updatePackedBoxes,
      putMessage
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanPackedBoxesForm);
