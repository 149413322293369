import { actionTypes } from "../../constants/Reports";
import { axiosProxy } from "../../utils/axiosProxy";
import { toEndDateString, toStartDateString } from "../../utils/dateUtils";
import {
  employeePickedCropsFiltersSelector,
  employeeBarcodesListSelector,
  selectEmployeeBarcodes
} from "../../selectors/reports";
import uuid from "uuid/v4";

const url = "/reporting_module";
let uniqId;

export const fetchReportsTypes = () => async dispatch => {
  dispatch({ type: actionTypes.FETCH_REPORT_TYPES_START });
  try {
    const params = new URLSearchParams();
    params.append("unpaged", "true");

    const res = await axiosProxy({
      url: `${url}/types`,
      method: "GET",
      params
    });

    dispatch({ type: actionTypes.FETCH_REPORT_TYPES, payload: res.data });
  } catch (e) {
    dispatch({ type: actionTypes.FETCH_REPORT_TYPES_FAIL });
  }
};

export const fetchCostsPerAreaList = item => async dispatch => {
  try {
    dispatch({ type: actionTypes.FETCH_REPORT_PER_AREA_LIST_START });
    const { dateFrom, dateTo, ...filters } = item;
    const from = filters.seasons && filters.seasons[0].slice(0, 10);
    const to = filters.seasons && filters.seasons[0].slice(-10);
    delete filters.seasons;
    const pureParams = !(dateFrom || dateTo)
      ? {
          ...filters,
          ...(from && { from: `${from}T00:00:00` }),
          ...(to && { to: `${to}T23:59:59` })
        }
      : {
          ...filters,
          ...(dateFrom && { from: `${dateFrom}T00:00:00` }),
          ...(dateTo && { to: `${dateTo}T23:59:59` })
        };
    const params = new URLSearchParams(pureParams);
    params.append("unpaged", "true");

    ["id,asc"].forEach(field => {
      params.append("sort", field);
    });

    const res = await axiosProxy({
      method: "GET",
      params,
      url: `${url}/costPerArea`
    });

    await dispatch({
      type: actionTypes.FETCH_REPORT_PER_AREA_LIST,
      payload: res.data
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_REPORT_PER_AREA_LIST_FAIL,
      payload: error
    });
  }
};
export const fetchCostsPerArea = ({
  page,
  size,
  search,
  sort,
  filters,
  from,
  to,
  unpaged = false
}) => async dispatch => {
  try {
    dispatch({ type: actionTypes.FETCH_REPORT_PER_AREA_START });

    const params = new URLSearchParams();
    Object.entries(filters).forEach(([name, value]) => {
      const from = value[0].slice(0, 10);
      const to = value[0].slice(-10);
      return name !== "seasons"
        ? Array.isArray(value)
          ? value.forEach(val => params.append(name, val))
          : params.append(name, value)
        : (params.append("from", `${from}T00:00:00`),
          params.append("to", `${to}T23:59:59`));
    });
    if (unpaged) {
      params.append("unpaged", "true");
    } else {
      params.append("page", page);
      params.append("size", size);
    }

    from && params.append("from", toStartDateString(from));
    to && params.append("to", toEndDateString(to));
    search && params.append("search", search);

    (sort || ["id,asc"]).forEach(field => {
      params.append("sort", field);
    });
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url: `${url}/costPerArea`
    });

    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionTypes.FETCH_REPORT_PER_AREA,
        payload: res.data
      });
    }
    return res.data;
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_REPORT_PER_AREA_FAIL,
      payload: error
    });
  }
};

export const fetchPickedCropPerEmployeeReport = ({
  filters,
  from,
  to
}) => async dispatch => {
  try {
    dispatch({ type: actionTypes.FETCH_PICKED_CROP_PER_EMPLOYEE_REPORT_START });
    const params = new URLSearchParams({
      ...filters,
      ...(from && { from }),
      ...(to && { to })
    });

    const res = await axiosProxy({
      method: "GET",
      params,
      url: `${url}/pickedCropsPerEmployee`
    });

    await dispatch({
      type: actionTypes.FETCH_PICKED_CROP_PER_EMPLOYEE_REPORT,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_PICKED_CROP_PER_EMPLOYEE_REPORT_FAIL,
      payload: error
    });
  }
};

export const fetchScannedBarcodesReport = ({
  page,
  size,
  search,
  sort,
  rawFilters = {},
  unpaged
}) => async dispatch => {
  try {
    const { dateFrom, dateTo, ...filters } = rawFilters;
    const pureParams = {
      ...filters,
      ...(search && { search }),
      ...(dateFrom && { from: `${dateFrom}T00:00:00` }),
      ...(dateTo && { to: `${dateTo}T23:59:59` })
    };
    const params = new URLSearchParams(pureParams);

    if (unpaged) {
      params.append("unpaged", "true");
    } else {
      params.append("page", page);
      params.append("size", size);
    }
    (sort || ["id,asc"]).forEach(field => {
      params.append("sort", field);
    });
    dispatch({ type: actionTypes.FETCH_SCANNED_BARCODES_REPORT_START });

    const res = await axiosProxy({
      method: "GET",
      params,
      url: `${url}/scannedGeneralBarcodes`
    });

    dispatch({
      type: actionTypes.FETCH_SCANNED_BARCODES_REPORT,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_SCANNED_BARCODES_REPORT_FAIL,
      payload: error
    });
  }
};

export const fetchScannedBarcodesByEmployeeId = employeeId => async (
  dispatch,
  getState
) => {
  const { dateFrom, dateTo, ...filters } = employeePickedCropsFiltersSelector(
    getState()
  );
  const params = new URLSearchParams({
    ...filters,
    unpaged: true,
    employeeIds: [employeeId],
    ...(dateFrom && { from: `${dateFrom}T00:00:00` }),
    ...(dateTo && { to: `${dateTo}T23:59:59` })
  });

  dispatch({
    type: actionTypes.TOGGLE_LOADING_BARCODES_BY_EMPLOYEE,
    payload: employeeId
  });

  const response = await axiosProxy({
    method: "GET",
    params,
    url: `${url}/scannedGeneralBarcodes`
  });
  const newBarcodesByEmployeeMap = {
    ...employeeBarcodesListSelector(getState()),
    [employeeId]: response.data.content
  };

  dispatch({
    type: actionTypes.TOGGLE_LOADING_BARCODES_BY_EMPLOYEE,
    payload: employeeId
  });
  dispatch({
    type: actionTypes.SET_EMPLOYEE_BARCODES_LIST,
    payload: newBarcodesByEmployeeMap
  });
};

export const submitReport = data => async dispatch => {
  dispatch({ type: actionTypes.SUBMIT_REPORT_START });
  try {
    await axiosProxy({
      url: `${url}/request_report`,
      method: "POST",
      data
    });

    dispatch({ type: actionTypes.SUBMIT_REPORT });
  } catch (e) {
    dispatch({ type: actionTypes.SUBMIT_REPORT_FAIL });
  }
};

export const clearError = () => dispatch =>
  dispatch({ type: actionTypes.CLEAR_ERROR });

export const setEmployeePickedCropsFilters = rawFilters => async dispatch => {
  const { batchIDs, ...filters } = rawFilters;
  const { dateFrom, dateTo } = rawFilters;

  dispatch({
    type: actionTypes.SET_EMPLOYEE_PICKED_CROPS_FILTERS,
    payload: filters
  });
  dispatch({
    type: actionTypes.SET_EMPLOYEE_BARCODES_LIST,
    payload: {}
  });
  dispatch(
    fetchPickedCropPerEmployeeReport({
      filters,
      from: dateFrom ? `${dateFrom}T00:00:00` : null,
      to: dateTo ? `${dateTo}T23:59:59` : null
    })
  );
};

export const fetchPackedBoxesList = ({
  page,
  size,
  search,
  sort,
  filters,
  unpaged
  // from,
  // to,
}) => async (dispatch, getState) => {
  try {
    dispatch({
      type: actionTypes.FETCH_PACKED_BOXES_START
    });
    const {
      employee: { list },
      areas
    } = getState();

    const params = new URLSearchParams(filters);
    // if (unpaged) {
    // } else {
    params.append("unpaged", true);
    params.append("page", 10);
    params.append("size", size);
    // }

    // from && params.append("from", from);
    // to && params.append("to", to);
    search && params.append("search", search);

    (sort || ["id,asc"]).forEach(field => {
      params.append("sort", field);
    });
    const res = await axiosProxy({
      method: "GET",
      params,
      url: "/packed_boxes"
    });
    const newData = res.data.content.map(boxe => ({
      ...boxe,
      employee: list.content.find(item => item.id === boxe.employeeId),
      area: areas.list.content.find(item => item.id === boxe.areaId)
    }));

    await dispatch({
      type: actionTypes.FETCH_PACKED_BOXES,
      payload: {
        ...res.data,
        content: newData
      }
    });
    return {
      ...res.data,
      content: newData
    };
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_PACKED_BOXES_FAIL,
      payload: error
    });
  }
};
